import axios from 'axios';
import { getBaseUrl } from '@/lib/lambda';

export const callPaginatedPostApi: any = async (
  db: string,
  endpoint: string,
  data: any,
  config: any,
) => {
  const apiParams = { ...data };

  let response;
  let hasNextPage = true;
  let resultDataRows = [];
  while (hasNextPage) {
    // eslint-disable-next-line no-await-in-loop
    const nextResult = await axios.post(
      getBaseUrl(db, endpoint),
      apiParams,
      config,
    );
    // eslint-disable-next-line no-await-in-loop
    response = await (nextResult.data.message as Record<any, any>);
    hasNextPage = response.nextOffset != null; // null or undefined
    if (response.results !== undefined) {
      resultDataRows = [...resultDataRows, ...response.results];
      apiParams.offset = response.nextOffset;
    }
  }

  if (response.columns) {
    response.csvResults = `${response.columns.join(',')}\n`;
    response.csvResults += resultDataRows
      .map((row) => row.join(','))
      .join('\n');
  }

  return response as any;
};

export const callGetApi: any = async (
  db: string,
  endpoint: string,
  data: any,
  config: any,
) => {
  const params = config;
  params.params = data;
  const nextResult = await axios.get(getBaseUrl(db, endpoint), params);
  const response = await (nextResult.data.message as Record<any, any>);
  return response as any;
};
