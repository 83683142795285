// some endpoints are in the vfa_serverless repo

const SANDBOX_BASE_URL =
  'https://uf25owq668.execute-api.us-east-1.amazonaws.com/dev/dashboard-data'; // sandbox (though there is no sandbox deployment of the dashboard)
// 'http://localhost:3333/dev/dashboard-data'; // for localhost testing

const PRODUCTION_BASE_URL =
  'https://37aze90rvd.execute-api.us-east-1.amazonaws.com/prod/dashboard-data'; // production
// 'http://localhost:3333/dev/dashboard-data'; // for localhost testing

export function getBaseUrl(db, endpoint) {
  const baseUrl = db === 'prod' ? PRODUCTION_BASE_URL : SANDBOX_BASE_URL;
  const response = `${baseUrl}/${endpoint}`;
  console.log(response);
  return response;
}

export const getBackendConfig = (
  jwtToken: string,
): Record<string, Record<string, string>> => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${jwtToken}`,
    },
  };
  return config;
};
